import { Injectable } from '@angular/core';
import { Project } from '../_interfaces/project';
import { Token } from '../_interfaces/token';

@Injectable({
    providedIn: 'root'
})
export class GlobalsProvider {

    constructor() {}

    private testnetOpenseaUrl = "https://testnets.opensea.io/assets";
    private mainnetOpenseaUrl = "https://opensea.io/assets";

    private mainnetAppBaseUrl = "https://app.ntent.art/n/";
    private testnetAppBaseUrl = "https://test.app.ntent.art/n/";

    /*
    /* Third party endpoints
    */

    public infuraProjectId = "7177842209804a36ac212fc4fb3850aa";

    private rinkebyTokenContract = "0x0efb05b9544ef5867c2510e79f6b25edf6cb56c8";
    private kovanTokenContract = "0xbCC3ffDc2709127bB725D778C90A383299c19Ae3";    
    private testNetwork = "kovan"

    private testInfuraEndpointUrl = "https://kovan.infura.io/v3/7177842209804a36ac212fc4fb3850aa";
    private testAlchemyEndpointUrl = `https://eth-${this.testNetwork}.alchemyapi.io/v2/1PNbHKbJAKb2272av47kSBcluYgMER4M`
    private mainnetAlchemyEndpointUrl = "https://eth-mainnet.alchemyapi.io/v2/DRZjwTHapoNSD6-bg20-zSiMrrbq5WlG";

    /*
    /* TX verifications endpoints
    */

    public testTxBaseUrl = "https://kovan.etherscan.io/tx/";
    public mainnetTxBaseUrl = "https://etherscan.io/tx/";
    
    /*
    /* Smart Contract Properties
    */
    private mainnetTokenContractAddress:string = "0x8Ec319e40E0A91A11cF584053EB5e420C613f01d";
    private testTokenContractAddress:string = this.kovanTokenContract;

    private mainnetPaymentSplitterAddress: string = "";
    private testPaymentSplitterAddress: string = "0x3F55B4b1aBA3d4e357539C3d9e4713dE5dD99D05";

    private production: boolean = false;

    public project: Project = new Project();
    public projects : Project[] = [];
    public userTokens : Token[] = [];
    public tokenBaseUri = "https://hosted.ntent.art/token-metadata/"
    public noWallet = false;
    public projectsLoaded = false;
    public tokensLoaded = false;
    public isMintPage = true;
    public justMinted = false;
    public isMobile = false;

    tokenContractAddress(){
        if(this.production)
            return this.mainnetTokenContractAddress;
        else 
            return this.testTokenContractAddress;
    }

    paymentSplitterContractAddress(){
        if(this.production)
            return this.mainnetPaymentSplitterAddress;
        else 
            return this.testPaymentSplitterAddress;
    }

    thirdpartyEndpoint(){
        if(this.production)
            return this.mainnetAlchemyEndpointUrl;
        else 
            return this.testAlchemyEndpointUrl;
    }

    txVerificationEndpoint(){
        if(this.production)
            return this.mainnetTxBaseUrl;
        else 
            return this.testTxBaseUrl;
    }

    openseaBaseUrl(){
        if(this.production)
        return this.mainnetOpenseaUrl;
    else 
        return this.testnetOpenseaUrl;
    }

    appBaseUrl(){
        if(this.production)
            return this.mainnetAppBaseUrl;
        else
            return this.testnetAppBaseUrl;
    }

    viewProjectOnOpenseaUrl(projectName){
        return `https://opensea.io/collection/ntent-art?search[query]=${encodeURIComponent(projectName)}&search[sortAscending]=false&search[sortBy]=VIEWER_COUNT`;
    }


}
