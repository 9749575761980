import { Component, OnInit, enableProdMode } from '@angular/core';
import { Platform } from '@ionic/angular';
import { GlobalsProvider } from './_providers/globals-provider';
import { ProjectProvider } from './_providers/project-provider';
import { Utils } from './_providers/utils';
import { Web3Provider } from './_providers/web3-provider';

enableProdMode();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit{
  constructor(
    public web3Provider: Web3Provider,
    public globals: GlobalsProvider,
    public projectProvider: ProjectProvider,
    private platform: Platform,
    public utils : Utils
    ) {
      this.initializeApp();
    }


  async ngOnInit(){
    
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      this.globals.isMobile = this.utils.mobileCheck();
      await this.web3Provider.connect(false);
      await this.projectProvider.getProjectDetailsBasic();
    });
  }
}
