import { Token } from "./token";

export class Project {
    id: string = "";
    name: string = "";
    desc:string = "";
    artist:string = "";
    artistWebsite: string = "";
    projectBaseUri: string = ""
    projectBaseIpfsUri : string = "";
    projectScriptUrl: string = "";
    license: string = "";
    mintPrice: number = 0;
    maxInvocations: number = 0;
    invocations: number = 0;
    userTokens : Token[] = [];
    populated: string = "";
    paused: boolean = true;
    active: boolean = false;
    locked: boolean = true;
    purchaseContract : string = "";
    acceptsMintPass: boolean = false;
    mintPassProjectId: string = "";
    tokensBurned: number = 0;

    constructor() {
    }
  }